<template>
<div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
  <!--begin::Card-->
  <div class="card">
    <!--begin::Card header-->
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title"></div>
      <!--begin::Card title-->
      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Toolbar-->
        <div
          class="d-flex justify-content-end"
          data-kt-user-table-toolbar="base"
        >
          <!--begin::Add user-->
          <router-link
            v-if="can('sub-title-create', 'all')"
            to="/apps/appeals/sub-title/create"
          >
            <button type="button" class="btn btn-primary">
              <!--begin::Svg Icon | path: icons/duotone/Navigation/Plus.svg-->
              <span class="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <rect
                    fill="#000000"
                    x="4"
                    y="11"
                    width="16"
                    height="2"
                    rx="1"
                  />
                  <rect
                    fill="#000000"
                    opacity="0.5"
                    transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000)"
                    x="4"
                    y="11"
                    width="16"
                    height="2"
                    rx="1"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->เพิ่ม หัวข้อเรื่องย่อย
            </button>
          </router-link>
          <!--end::Add user-->
        </div>
        <!--end::Toolbar-->
      </div>
      <!--end::Card toolbar-->
    </div>
    <!--end::Card header-->
    <!--begin::Card body-->
    <div class="card-body pt-0">
      <div class="table-responsive">
        <!--begin::Table-->
        <table
          class="table align-middle table-row-dashed fs-6 gy-5"
          id="kt_table_users"
          style="border-collapse: unset"
        >
          <!--begin::Table head-->
          <thead>
            <!--begin::Table row-->

            <tr
              class="
                text-start text-gray-400
                fw-bolder
                fs-7
                text-uppercase
                gs-0
              "
            >
              <th class="min-w-125px">หัวข้อเรื่อง</th>
              <th class="min-w-125px">หัวข้อเรื่องย่อย</th>

              <th class="text-end min-w-100px">จัดการ</th>
            </tr>
            <!--end::Table row-->
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody class="text-gray-600 fw-bold">
            <!--begin::Table row-->
            <template v-for="sub_title in sub_titles" :key="sub_title.id">
              <tr>
                <td class="align-items-center">{{ sub_title.title }}</td>
                <td class="align-items-center">{{ sub_title.name }}</td>

                <!--begin::Action=-->
                <td class="text-end">
                  <a
                    href="#"
                    class="btn btn-light btn-active-light-primary btn-sm"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="top-end"
                    >จัดการ
                    <!--begin::Svg Icon | path: icons/duotone/Navigation/Angle-down.svg-->
                    <span class="svg-icon svg-icon-5 m-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            transform="translate(12.000003, 11.999999) rotate(-180.000000) translate(-12.000003, -11.999999)"
                          />
                        </g>
                      </svg>
                    </span>
                    <!--end::Svg Icon--></a
                  >

                  <!--begin::Menu-->
                  <div
                    class="
                      menu
                      menu-sub
                      menu-sub-dropdown
                      menu-column
                      menu-rounded
                      menu-gray-600
                      menu-state-bg-light-primary
                      fw-bold
                      fs-7
                      w-125px
                      py-4
                    "
                    data-kt-menu="true"
                  >
                    <!--begin::Menu item-->
                    <div
                      v-if="can('sub-title-edit', 'all')"
                      class="menu-item px-3"
                    >
                      <router-link
                        :to="{
                          name: 'apps.appeals.sub-titles.edit',
                          params: { id: sub_title.id },
                          props: { id: sub_title.id },
                        }"
                      >
                        <a class="menu-link px-3">แก้ไข</a>
                      </router-link>
                    </div>
                    <!--end::Menu item-->
                    <!--begin::Menu item-->
                    <div
                      v-if="can('sub-title-delete', 'all')"
                      class="menu-item px-3"
                    >
                      <a
                        @click="delateSubTitle(sub_title.id)"
                        class="menu-link px-3"
                        >ลบ</a
                      >
                    </div>
                    <!--end::Menu item-->
                  </div>
                  <!--end::Menu-->
                </td>
                <!--end::Action=-->
              </tr>
              <!--end::Table row-->
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <Pagination v-bind:meta="meta" @pagination="updateHandler"></Pagination>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
   </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { defineComponent, onMounted, onUpdated,ref } from "vue";
import {
  MenuComponent,
  ToggleComponent,
  DrawerComponent,
} from "@/assets/js/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkPage } from "@/core/helpers/checkpage";
import useSubTitles from "@/core/services/api/appeal/sub_titles";
import useSweetalert from "@/core/helpers/sweetalert2";
import { useAbility } from "@casl/vue";

export default defineComponent({
  name: "sub-titles",
  components: {
    Pagination,
  },
  setup() {
    const sub_titles = ref([]);
    const meta = ref([]);
    const { getSubTitles, destroySubTitle  } = useSubTitles();
    const { Sconfirm, SToast,SconfirmNc } = useSweetalert();
    const { can } = useAbility();
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);
    onMounted(() => {
      getSubTitles().then((response) => {
        sub_titles.value = response.data.data;
        meta.value = response.data.meta;
        isLoading.value = false;
      })
      .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
          isLoading.value = false;
        });
      checkPage("sub-title-list");
      setCurrentPageBreadcrumbs("จัดการหัวข้อเรื่องย่อย");
      DrawerComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
      MenuComponent.reinitialization();
    });

    function delateSubTitle(id) {
      Sconfirm("ยืนยันการลบ", "question").then(async (result) => {
        if (result.isConfirmed) {
          await destroySubTitle(id);
          await getSubTitles();
          SToast("success", "ลบ หัวข้อเรื่องย่อย สำเร็จ");
        }
      });
    }

    async function updateHandler(e) {
      isLoading.value = true;
       getSubTitles(e).then((response) => {
        sub_titles.value = response.data.data;
        meta.value = response.data.meta;
        isLoading.value = false;
      })
      .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
          isLoading.value = false;
        });
    }

    return { can, sub_titles, delateSubTitle, updateHandler, meta,isLoading,color };
  },
});
</script>

<style scoped></style>
