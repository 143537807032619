import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useSubTitles() {
  const titles = ref([]);
  const sub_title = ref();
  const router = useRouter();

  const getSubTitles = async (page) => {
    return await ApiService.query("sub-titles",page);
  };

  const getTitles = async () => {
    let response = await ApiService.get("sub-titles-titles");
    titles.value = response.data.data;
  };

  const getSubTitle = async (id) => {
    let response = await ApiService.get("sub-titles/" + id);

    sub_title.value = response.data.data.sub_title;
    titles.value = response.data.data.titles;
  };

  const destroySubTitle = async (id) => {
    await ApiService.delete("sub-titles/" + id);
  };

  const stoerSubTitle = async (data) => {
    await ApiService.post("sub-titles", data);
    await router.push({ name: "apps.appeals.sub-titles.index" });
  };

  const updateSubTitle = async (data) => {
    await ApiService.put("sub-titles/" + data.id, data);
    await router.push({
      name: "apps.appeals.sub-titles.edit",
      params: { id: data.id },
    });
  };

  return {
    getSubTitles,
    getSubTitle,
    getTitles,
    titles,
    sub_title,
    destroySubTitle,
    stoerSubTitle,
    updateSubTitle,
  };
}
